
.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 6.5em;
    height: 2em;
    border-radius: 4px;
    margin: 5px 3px 0px 0px;
    background-color: #141f32;
}

.react-datepicker__input-container input {
    width: 6em;
    height: 1.5em;
    font-family: Rajdhani;
    font-size: 1em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: auto;
    letter-spacing: normal;
    text-align: center;
    background-color: #141f32;
    border: none;
    color: #6591b2;
}